.article {
  display: block;
  text-decoration: none;
  margin-top: 48px;
  max-width: 34em;
  
  @media screen and (min-width: 768px) {
    margin-top: 64px;
  }

  &:hover {
    h3 {
      text-decoration: underline;
    }
  }
}