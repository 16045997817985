@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@500;700;800&display=block');

@font-face {
  font-family: Variable;
  src: url('fonts/Flexible-Variable.ttf');
}

body {
  font-size: 18px;
  line-height: 1.4;
  font-family: "Figtree", sans-serif;
  color: $color-black;

  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
}

.variable,
.h0, h1, .h1 {
  font-family: "Variable", sans-serif;
}

.h0 {
  font-size: 176px;
  line-height: .7;
  letter-spacing: 0;
  word-spacing: 0.05em;
  font-weight: 400;
  pointer-events: none;

  @media screen and (min-width: 768px) {
    font-size: 220px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 290px;
    padding-left: 10px; // ok this one is random, but is required to make the DRAGON DROP content on the homepage appear more centrally aligned
  }
}

h1, .h1 {
  font-size: 106px;
  line-height: 1;
  letter-spacing: 0.025em;
  margin-bottom: .2em;
  font-weight: 400;
  pointer-events: none;
  
  @media screen and (min-width: 768px) {
    font-size: 176px;
    line-height: .7;
    letter-spacing: 0;
    word-spacing: 0.05em;
  }

  @media screen and (min-width: 1200px) {
    font-size: 276px;
  }
}

.post-title {
  margin-bottom: .025em;
  letter-spacing: 0.0125em;
  word-spacing: 0.05em;
  line-height: .7;
}

h2, .h2 {
  font-size: 28px;
  line-height: 1;
  letter-spacing: -0.02em;
  font-weight: 700;
  
  @media screen and (min-width: 768px) {
    font-size: 52px;
  }
}

h3, .h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.01em;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
}

.lede {
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  
  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
}

.small {
  font-size: .9em;
}

.xsmall {
  font-size: .8em;
}

a {
  color: $color-black;
}

*:not(pre) > code {
  font-family: monospace;
  font-weight: normal;
  font-size: 0.9em;
}