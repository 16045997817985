@charset "utf-8";

@import "vars";
@import "reset";
// @import "typography";
@import "typography2";;

// base

body {
  padding: 24px;

  @media screen and (min-width: 768px) {
    padding: 48px;
  }
}

main {
  margin-bottom: 10vh;
}

hr {
  height: 2px;
  border: 0;
  margin: 48px 0;
  background-color: $color-black;
  opacity: 0.1;
  
  &.chonker {
    opacity: 1;
    height: 20px;
    max-width: 237px;
  
    @media screen and (min-width: 768px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

img {
  max-width: 110%;
  margin-left: -5%;
}

div.highlight {
  width: 110%;
  margin-left: -5%;
}

pre.highlight {
  padding: 20px;
  overflow: auto;
}

// components

@import "components/button";
@import "components/header";
@import "components/article";
@import "components/banner-message";
@import "components/carousel";

.logo {
  &-wrapper {
    text-align: center;
  }

  svg {
    width: 66px;
    height: 66px;

    path {
      fill: $color-black;
    }
  }
}

.lilbowwow {
  display: inline-block;
  position: relative;

  &::before {
    content: "";
    height: 2px;
    background-color: $color-black;

    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100vw;

    transform-origin: top right;
    transform: rotate(-36deg);
  }
}

.content {
  max-width: 940px;
  margin: 0 auto;
}

.footer {
  padding-top: 48px;
}

.intro {
  > * + * {
    margin-top: 48px;
  }

  & {
    margin-bottom: 96px;
  }

  h2 {
    position: relative;
  }

  .h0 .lilbowwow::before {
    top: 28px;
    right: 45px;

    @media screen and (min-width: 768px) {
      top: 35px;
      right: 57px;
    }

    @media screen and (min-width: 1200px) {
      top: 46px;
      right: 75px;
    }
  }

  @media screen and (min-width: 768px) {
    text-align: center;
  }
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(2,minmax(0,1fr));
  gap: 48px;
  margin-top: 48px;
  text-align: center;

  div > p:first-of-type {
    font-weight: bold;

    + p {
      font-size: 16px;
    }
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
}

.form {
  label {
    display: block;
    margin-bottom: 2px;
    font-weight: 700;
  }

  input, textarea, button {
    border: none;
  }

  input[type="text"], input[type="email"], textarea {
    display: block;
    padding: 12px;
    width: 100%;
    max-width: 480px;
    background-color: transparent;
    border: solid 3px $color-black;
  }
  
  input[type="text"], input[type="email"] {
    font-size: 22px;
  }

  textarea {
    font-size: 18px;
    font-family: inherit;
  }

  &-group {
    & + & {
      margin-top: 24px;
    }
  }
}

@import "utilities";

@import "post";