// post layout/page styling
// mostly markdown defaults like adding margins to text

.post-page {
  background-color: $color-cotton;

  .post-title,
  .post-content a {
    color: $color-red;
  }

  *:not(pre) > code {
    background: rgba(#FFE497, .5);
    color: #36393B;
    border-radius: 0.2em;
    padding: 0.08em 0.25em;
    word-break: break-word;
  }
}

.post-content {
  .lede {
    margin-top: 2em;
  }

  .meat {
    > *:not(img, hr) {
      margin-top: 1.25em;
    }

    > *:not(img, .r16-9, hr, [class*="language"])  {
      max-width: 800px;
    }
  
    > h3, > h4 {
      margin-top: 2em;
      margin-bottom: -.5em;
    }
  
    a {
      text-decoration: underline;
    }
  
    ul, ol {
      margin-left: 1em;

      li {
        margin-top: .5em;
      }
    }  
  
    blockquote {
      @extend .lede;
      font-style: italic;

      border-left: solid 10px $color-black;
      padding-left: 24px;

      > * + * {
        margin-top: 1em;
      }
    }

    img {
      margin-top: 2em;
    }

    .r16-9 {
      position: relative;
      padding-top: 56.25%;

      @media screen and (min-width: 768px) {
        left: -4vw;
      }
    }

    .r16-9 > * {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%
    }

    figure {
      margin-top: 2em;

      img {
        margin-top: 0;
      }
    }

    figcaption {
      margin-top: .5em;
      text-align: center;
      font-size: 16px;
    }

    hr {
      margin-top: 96px;
      margin-bottom: 0;
    }
  }
}