@charset "utf-8";

@import "vars";
@import "reset";
// @import "typography";
@import "typography2";;

// base

body {
  
}

main {
  margin-bottom: 10vh;
}

hr {
  height: 2px;
  border: 0;
  margin: 48px 0;
  background-color: $color-black;
  opacity: 0.1;
  
  &.chonker {
    opacity: 1;
    height: 20px;
    max-width: 237px;
  
    @media screen and (min-width: 768px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

img {
  max-width: 110%;
  margin-left: -5%;
}

div.highlight {
  width: 110%;
  margin-left: -5%;
}

pre.highlight {
  padding: 20px;
  overflow: auto;
}

// components

@import "components/button";
@import "components/header";
@import "components/article";
@import "components/banner-message";
@import "components/carousel";

.logo {
  &-wrapper {
    text-align: center;
  }

  svg {
    width: 66px;
    height: 66px;

    path {
      fill: $color-black;
    }
  }
}

.lilbowwow {
  display: inline-block;
  position: relative;

  &::before {
    content: "";
    height: 2px;
    background-color: $color-black;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    width: 100vw;

    transform-origin: top right;
    transform: rotate(-36deg);
  }
}

.container {
  position: relative;
  padding: 24px;

  @media screen and (min-width: 768px) {
    padding: 48px;
  }
}

.container-x {
  position: relative;
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (min-width: 768px) {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.what-did-he-say-dear {
  position: relative;

  --diagonally: 25px;
  margin-bottom: 24px;
  
  @media screen and (min-width: 768px) {
    --diagonally: 100px;
    margin-bottom: 72px;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: calc(-1 * var(--diagonally));
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% + calc(2 * var(--diagonally)));
    clip-path: polygon(0% 0%, 100% var(--diagonally), 100% 100%, 0% calc(100% - var(--diagonally)));
    background-color: $color-red;
  }
}


.content {
  max-width: 940px;
  margin: 0 auto;
}

.footer {
  padding-top: 48px;
}

.intro {
  > * + * {
    margin-top: 48px;
  }

  & {
    margin-bottom: 48px;
  }

  h2 {
    position: relative;
  }

  .h0 .lilbowwow::before {
    top: 28px;
    right: 45px;

    @media screen and (min-width: 768px) {
      top: 35px;
      right: 57px;
    }

    @media screen and (min-width: 1200px) {
      top: 46px;
      right: 75px;
    }
  }

  @media screen and (min-width: 768px) {
    text-align: center;
  }
}

.team-member {
  display: flex;

  &__initial {
    @extend .h1;

    display: none;

    @media screen and (min-width: 768px) {
      display: block;
      color: $color-red;
    }
  }

  &__sub {
    display: flex;
    align-items: center;
    gap: 16px;

    margin-top: 8px;
    margin-bottom: 16px;
  }

  &__role {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;

    opacity: 0.8;
  }

  &__linkedin {
    position: relative;
    top: -1px;
    font-size: 16px;
  }
}

.team-grid {
  display: grid;
  gap: 48px;
  
  .team-member {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
}

.team-list {
  display: flex;
  flex-direction: column;
  gap: 48px;

  .team-member {
    flex-direction: row;
  }

  .team-member__initial {
    flex: 0 0 150px;

    @media screen and (min-width: 768px) {
      position: relative;
      top: -21px;
    }

    @media screen and (min-width: 1200px) {
      top: -36px;
      flex: 0 0 230px;
    }
  }
}

.form {
  label {
    display: block;
    margin-bottom: 2px;
    font-weight: 700;
  }

  input, textarea, button {
    border: none;
  }

  input[type="text"], input[type="email"], textarea {
    display: block;
    padding: 12px;
    width: 100%;
    max-width: 480px;
    background-color: transparent;
    border: solid 3px $color-black;
  }
  
  input[type="text"], input[type="email"] {
    font-size: 22px;
  }

  textarea {
    font-size: 18px;
    font-family: inherit;
  }

  &-group {
    & + & {
      margin-top: 24px;
    }
  }
}

@import "utilities";

@import "post";