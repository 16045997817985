.header {
  position: relative;
  margin-bottom: 48px;

  .post-page & {
    margin-bottom: 0;
  }

  .logo {
    position: relative;
    z-index: 10;
  }

  nav {
    display: flex;

    > * + * {
      margin-left: 18px;
    }

    a {
      font-weight: 700;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 500px) {
    nav {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 767px) {
    nav {
      margin: 16px 0;

      .contact {
        position: absolute;
        top: 12px;
        right: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    nav {
      display: flex;
      position: absolute;
      top: 20px;
      left: 0px;
      right: 0px;

      > * + * {
        margin-left: 24px;
      }

      .gems {
        margin-left: auto;
      }

      .contact {
        position: relative;
        top: -4px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    nav {
      .gems {
        margin-left: 24px;
      }

      .team {
        margin-left: auto;
      }
    }
  }
}