.hidden {
  display: none;
}

.center {
  text-align: center;
}

.no-wrap {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.font-bold {
  font-weight: bold;
}

.font-normal {
  font-weight: 400;
}

@for $i from 1 through 24 {
  .mt-#{$i} { margin-top: ($i * 4px) }
  .mb-#{$i} { margin-bottom: ($i * 4px) }
}

.text-black { color: $color-black;} 
.bg-black { background-color: $color-black;} 
.text-red { color: $color-red;} 
.bg-red { background-color: $color-red;} 
.text-green { color: $color-green;} 
.bg-green { background-color: $color-green;} 
.text-cotton { color: $color-cotton;} 
.bg-cotton { background-color: $color-cotton;} 
.text-yellow { color: $color-yellow;} 
.bg-yellow { background-color: $color-yellow;} 
.text-purple { color: $color-purple;} 
.bg-purple { background-color: $color-purple;} 
.text-orange { color: $color-orange;} 
.bg-orange { background-color: $color-orange;}