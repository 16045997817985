.carousel {
  width: 100vw;
  max-width: 9999px !important;
  user-select: none;

  display: flex;

  img {
    object-fit: cover;
    margin-left: 0 !important;
  }

  @media screen and (max-width: 767px) {
    margin-left: -24px;
  }

  @media screen and (min-width: 768px) and (max-width: 939px) {
    margin-left: -48px;
  }

  @media screen and (max-width: 939px) {
    overflow: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    img {
      max-width: 75% !important;
      scroll-snap-align: start;
    }

    > :first-child {
      order: 1;
    }
  }
  @media screen and (min-width: 940px) {
    margin-left: calc((100vw - 940px) / -2);
    justify-content: center;
    overflow: hidden;

    img {
      max-width: 100% !important;
    }
  }
}