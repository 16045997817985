.button {
  padding: 8px 10px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 800;
  text-transform: uppercase;
  color: $color-red;
  background-color: $color-black;
  cursor: pointer;
}

.button--big {
  padding: 10px 12px;
  font-size: 20px;
}