.banner-message {
  display: block;

  margin: 0 -24px;
  padding: 10vmax 24px;
  color: $color-cotton;
  background-color: $color-black;
  text-decoration: none;

  &:hover h2 {
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    margin: 0 -48px;
    padding: 10vmax 48px;
  }

  .content {
    * { max-width: 800px; }

    p {
      margin-top: 1em;
    }
  }
}

.banner-message--inverse {
  color: $color-black;
  background-color: $color-cotton; 
}