.article {
  display: block;
  text-decoration: none;
  max-width: 34em;
  
  & + & {
    margin-top: 48px;

    @media screen and (min-width: 768px) {
      margin-top: 64px;
    }
}

  &:hover {
    h3 {
      text-decoration: underline;
    }
  }
}